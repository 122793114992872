<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <field-input title="기준년월">
          <v-text-field
            v-model="baseDate"
            outlined dense hide-details
            background-color="white"
          />
        </field-input>
        <field-input title="활동여부">
          <v-select
              v-model="useYn"
              :items="useYnList"
              item-text="useYnNm"
              item-value="useYn"
              outlined dense hide-details
              background-color="white"
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="search" />
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout.vue";
import SearchArea from "@/components/Search/SearchArea.vue";
import FieldInput from "@/components/Form/FieldInput.vue";
import SearchBtn from "@/components/Search/SearchBtn.vue";

export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  data() {
    return {
      baseDate: "",
      useYn: "ALL",
      useYnList: [
        { useYnNm: "전체", useYn: "ALL" },
        { useYnNm: "Y", useYn: "Y" },
        { useYnNm: "N", useYn: "N" }
      ]
    }
  },
  computed: {
    nowDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;

      return `${year}${month < 10 ? "0" + month : month}`;
    }
  },
  mounted() {
    this.baseDate = this.nowDate;
  },
  methods: {
    search() {
      const params = {
        baseDate: this.baseDate,
        useYn: this.useYn
      };
      this.$emit("search", params);
    }
  }
}
</script>