<template>
  <search-page-layout title="OB인카 회원관리">
    <template v-slot:searchBar>
      <OBIncarMemberSearchBar
        @search="getSearch"
      />
    </template>
    <template v-slot:table>
      <OBIncarMemberTable
        :data="data"
        :loading="loading"
        @toExcel="downloadExcel"
      />
    </template>
  </search-page-layout>
</template>
<script>
import SearchPageLayout from "@/layouts/SearchPageLayout.vue";
import OBIncarMemberSearchBar from "@/pages/User/components/OBIncarMember/OBIncarMemberSearchBar.vue";
import OBIncarMemberTable from "@/pages/User/components/OBIncarMember/OBIncarMemberTable.vue";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";

export default {
  components: {OBIncarMemberTable, OBIncarMemberSearchBar, SearchPageLayout},
  data() {
    return {
      data: [],
      loading: false
    }
  },
  methods: {
    async getSearch(params) {
      try {
        const { data } = await axiosInstance.get("/admin/operation/1click/campaign/obIncarList", { params });
        if (data.length < 1) {
          this.data = [];
        } else {
          this.data = data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    downloadExcel() {
      // 리스트 빈 값 체크
      if(_.isEmpty(this.data)) return;
      const data = _.cloneDeep(this.data);
      const returnData = [];

      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      const nowDate = `${year}${month < 10 ? "0" + month : month}`;

      // 엑셀 다운로드
      for (const item of data) {
        const obj = {
          "회원명": item["memberNm"],
          "이메일": item["email"],
          "전화번호": item["telNum"],
          "생년월일": item["birthday"],
          "최근셀계지원요청일자": item["designReqDate"],
          "최근실적일자": item["settlementDate"],
          "활동여부": item["useYn"]
        }
        returnData.push(obj);
      }

      const sheetName = `${nowDate}`;
      const fileName = `OB인카 회원현황_${nowDate}`;
      func.extractExcelFile(returnData, sheetName, fileName);
    }
  }
}
</script>