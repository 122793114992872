<template>
  <v-flex class="mx-2">
    <div class="d-flex align-center justify-space-between">
      <span class="text-h6">목록 (총 {{ data.length }}건)</span>
      <outlined-btn class="excel-button" @click="toExcel" :disabled="dataLength">엑셀다운로드</outlined-btn>
    </div>
    <v-data-table
      class="mt-2"
      height="65vh"
      :headers="getHeader(headers)"
      :items="data"
      :loading="loading"
      fixed-header
      :items-per-page="-1"
    >
      <template v-slot:item.telNum="{ value }">
        {{ getTelNum(value) }}
      </template>
      <template v-slot:item.birthday="{ value }">
        {{ getDotsBirthday(value) }}
      </template>
      <template v-slot:item.designReqDate="{ value }">
        {{ contentsDataFormatter(value) }}
      </template>
      <template v-slot:item.settlementDate="{ value }">
        {{ contentsDataFormatter(value) }}
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import {OB_INCAR_MEMBER_TABLE} from "@/util/TableHeaders";
import TableMixin from "@/mixin/TableMixin";
import OutlinedBtn from "@/pages/Settlement/components/PaymentManage/outlinedBtn.vue";
import DateMixin from "@/mixin/DateMixin";

export default {
  components: {OutlinedBtn},
  props: ["data", "loading"],
  mixins: [TableMixin, DateMixin],
  data() {
    return {
      headers: OB_INCAR_MEMBER_TABLE
    }
  },
  computed: {
    dataLength() {
      return this.data.length < 1;
    }
  },
  methods: {
    toExcel() {
      this.$emit("toExcel");
    }
  }
}
</script>

<style scoped>
.excel-button:hover {
  color: green;
  border: green 1px solid;
}
</style>